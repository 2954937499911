import React from "react";
import Card from "../common/components/Card";

type Props = {
  text: string;
};

function TutorialSlide(props: Props) {
  return (
    // onClick to catch doubleTaps
    // touchAction to catch pinch-to-zoom
    <div className="flex items-end h-full" onClick={() => {}} style={{ touchAction: "pan-x pan-y" }}>
      <div className="w-full mx-2 my-3 text-sm">
        <Card small={true}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.text,
            }}
          />
        </Card>
      </div>
    </div>
  );
}

export default TutorialSlide;
