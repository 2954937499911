import { Navigate } from "react-router-dom";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { AppData } from "../open/AppData";

type Props = {};

function ScreenSize(props: Props) {
  useInvocationCheck();

  const screenIsBigEnough = AppData.useState((s) => s.screenIsBigEnough);

  // TODO add additional conditions depending on code type and/or card size
  if (screenIsBigEnough) {
    return <Navigate to="/tutorial" replace />;
  }

  return <Navigate to="/notice" replace />;
}

export default ScreenSize;
