import React, { useEffect, useState } from "react";
import { TutorialData } from "./TutorialData";

import image1 from "./img/01_welcome-ID.gif";
import image2 from "./img/02_placement-ID.gif";
import image3 from "./img/03_swipe-ID.gif";
import TutorialImageContainer from "./TutorialImageContainer";

type Props = {};

type ImageDetails = {
  gif: string;
  title: string;
};

function TutorialImage(props: Props) {
  const currentSlide = TutorialData.useState((s) => s.currentSlide);
  const [imageDetails, setImageDetails] = useState<ImageDetails>({
    gif: "",
    title: "",
  });

  useEffect(() => {
    const images = [
      { gif: image1, title: "welcome" },
      { gif: image2, title: "placement" },
      { gif: image3, title: "swipe" },
    ];
    setImageDetails({
      gif: images[currentSlide]?.gif || "",
      title: images[currentSlide]?.title || "no gif found",
    });
  }, [currentSlide]);

  return (
    <TutorialImageContainer>
      <img
        src={imageDetails.gif}
        alt={imageDetails.title}
        className="object-cover w-screen h-auto mt-8 overflow-hidden tutorialImage"
      />
    </TutorialImageContainer>
  );
}

export default TutorialImage;
