import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import anime from "animejs";
import MessageTitle from "../common/components/MessageTitle";
import IconButton from "../common/ui/IconButton";
import Sound from "../common/sound/Sound";
import click from "../../assets/sounds/click.mp3";

import bg from "../../assets/img/bg/bg2.png";
import map from "./img/verified_map@2x.png";
import germany from "./img/verified_germany@2x.png";
import zoom from "./img/verified_zoom@2x.png";
import card from "./img/verified_card@2x.png";
import holo_portrait from "./img/verified_portrait@2x.png";
import holo_guilloche from "./img/verified_guilloche@2x.png";
import holo_logo from "./img/verified_hologram@2x.png";
import check from "./img/verified_checkmark@2x.png";

import pdf from "./files/prismade_security.pdf";
import { useDataReset } from "../../hooks/useDataReset";
import Button from "../common/ui/Button";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import MotionPage from "../common/layout/MotionPage";

type Props = {};

function Verified(props: Props) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const clickSound = new Sound(click);

  useInvocationCheck();
  useDataReset();

  useEffect(() => {
    anime.set(["#check_container"], {
      scale: 0.5,
      opacity: 0.0,
    });
    anime.set(["#germany", "#zoom", "#card", "#holo_logo", "#holo_guilloche", "#holo_portrait"], {
      opacity: 0,
    });

    setTimeout(() => {
      anime({
        targets: ["#check_container"],
        opacity: 1.0,
        scale: 1.1,
        duration: 1500,
        delay: 2000,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#check_container"],
            scale: 1,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });

      anime({
        targets: ["#germany"],
        scale: 1.2,
        opacity: 1,
        duration: 1000,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#germany"],
            scale: 1.0,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });
      anime({
        targets: ["#zoom"],
        opacity: 1,
        duration: 1000,
        delay: 1000,
        easing: "linear",
      });
      anime({
        targets: ["#card"],
        scale: 1.2,
        opacity: 1,
        duration: 1000,
        delay: 1500,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#card"],
            scale: 1.0,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });
      anime({
        targets: ["#holo_portrait"],
        scale: 1.2,
        opacity: 1,
        duration: 1500,
        delay: 3500,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#holo_portrait"],
            scale: 1.0,
            opacity: 0,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });
      anime({
        targets: ["#holo_guilloche"],
        scale: 1.2,
        opacity: 1,
        duration: 1500,
        delay: 4500,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#holo_guilloche"],
            scale: 1.0,
            opacity: 0,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });
      anime({
        targets: ["#holo_logo"],
        scale: 1.2,
        opacity: 1,
        duration: 1500,
        delay: 5500,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#holo_logo"],
            scale: 1.0,
            opacity: 0,
            duration: 500,
            easing: "easeInOutQuad",
          });
        },
      });
    }, 1500);
  }, []);

  return (
    <MotionPage className="h-full">
      <OrientationLock>
        <div
          className="flex flex-col items-center justify-between w-screen h-full p-8 bg-white bg-bottom bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="relative flex items-center justify-center w-screen text-center">
            <div className="absolute left-0">
              <IconButton
                icon="arrow-left"
                onClick={() => {
                  clickSound.play();
                  navigate("/start", { replace: true });
                }}
              />
            </div>
            <MessageTitle className="text-green-600">{t("success:title")}</MessageTitle>
          </div>
          <div className="relative flex justify-center w-screen mt-4 h-116">
            <img src={map} id="map" alt="" className="absolute mx-auto" />
            <img src={germany} id="germany" alt="" className="absolute mx-auto" style={{ transformOrigin: "50% 20%" }} />
            <img src={zoom} id="zoom" alt="" className="absolute mx-auto" />
            <img src={card} id="card" alt="" className="absolute mx-auto" />
            <img src={holo_logo} id="holo_logo" alt="" className="absolute mx-auto" style={{ transformOrigin: "70% 75%" }} />
            <img src={holo_guilloche} id="holo_guilloche" alt="" className="absolute mx-auto" style={{ transformOrigin: "50% 75%" }} />
            <img src={holo_portrait} id="holo_portrait" alt="" className="absolute mx-auto" style={{ transformOrigin: "25% 80%" }} />
          </div>
          <div id="check_container" className="mt-8">
            <img src={check} alt="checkmark" className="h-24 mx-auto mt-4" />
            <p className="text-2xl font-semibold text-center text-green-600 ">{t("success:cardName")}</p>
          </div>
          <div className="w-full mt-4">
            <Button
              onClick={() => {
                window.open(pdf);
              }}
            >
              {t("success:button")}
            </Button>
          </div>
        </div>
      </OrientationLock>
    </MotionPage>
  );
}

export default Verified;
