import React, { useEffect, useState } from "react";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useBodyScrollLock } from "../../hooks/useBodyScrollLock";

import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import TutorialComponent from "./TutorialComponent";
import MotionPage from "../common/layout/MotionPage";

type Props = {};

function Tutorial(props: Props) {
  useInvocationCheck();
  useBodyScrollLock();
  const [deviceOnTable, setDeviceOnTable] = useState(false);


  function handleOrientation(event: any) {
    let alpha = event.alpha; // In degree in the range [-180,180)
    let beta = event.beta
    let gamma = event.gamma
    if (beta && gamma && Math.abs(beta) < 1.5 && Math.abs(gamma) < 2){
      setDeviceOnTable(true)
    } else {
      setDeviceOnTable(false)

    }
  }

  useEffect(() => {
    //window.addEventListener("deviceorientation", handleOrientation);
  }, []);

  if (deviceOnTable) {
    return <div><p>please hold the device in hand</p></div>;
  }

  return (
    <MotionPage className="h-full">
      <OrientationLock>
        <TutorialComponent />
      </OrientationLock>
    </MotionPage>
  );
}

export default Tutorial;
