import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ErrorCode } from "../../enums/ErrorCode";
import { AppData } from "../open/AppData";
import StartComponent from "./StartComponent";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useDataReset } from "../../hooks/useDataReset";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import MotionPage, { MotionPageVariant } from "../common/layout/MotionPage";

function Start() {
  const [redirect, setRedirect] = useState("");
  const [deviceParallelToGround, setDeviceParallelToGround] = useState(false);
  const [deviceNotMoving, setDeviceNotMoving] = useState(false);
  const [beta, setBeta] = useState(0);
  const [gamma, setGamma] = useState(0);





  const invocationIsValid = AppData.useState((s) => s.invocationIsValid);
  const browserIsSupported = AppData.useState((s) => s.browserIsSupported);
  const deviceIsSupported = AppData.useState((s) => s.deviceIsSupported);
  const displayZoomEnabled = AppData.useState((s) => s.displayZoomEnabled);

  function handleOrientation(event: any) {
    let beta = event.beta
    let gamma = event.gamma
    setBeta(beta)
    setGamma(gamma)
    if (beta && gamma && Math.abs(beta) < 3 && Math.abs(gamma) < 2){
      setDeviceParallelToGround(true)
    } else {
      setDeviceParallelToGround(false)

    }
  }

  function handleDeviceMotion(event: any) {
    let x = event.acceleration.x;
    let y = event.acceleration.y
    let z = event.acceleration.z
    let closeToZeroSpeedCount = 0;
    if(x <= 0.1){
      closeToZeroSpeedCount++;
    }
    if(y <= 0.1){
      closeToZeroSpeedCount++;
    }
    if(z <= 0.1){
      closeToZeroSpeedCount++;
    }

    if(closeToZeroSpeedCount >= 2){
      setDeviceNotMoving(true)
    } else {
      setDeviceNotMoving(false)
    }
    //console.log(event.rotationRate.alpha +", " + event.rotationRate.beta + ", " +event.rotationRate.gamma)
  }

  useInvocationCheck();
  useDataReset();

  useEffect(() => {
    if (!browserIsSupported) {
      setErrorCodeAndRedirect(ErrorCode.UnsupportedBrowser);
      return;
    }
    if (!deviceIsSupported) {
      setErrorCodeAndRedirect(ErrorCode.UnsupportedDevice);
      return;
    }
    if(displayZoomEnabled) {
      setErrorCodeAndRedirect(ErrorCode.DisplayZoomEnabled);
    }
    //window.addEventListener("deviceorientation", handleOrientation);
    //window.addEventListener("devicemotion", handleDeviceMotion);


  }, [invocationIsValid, browserIsSupported, deviceIsSupported, displayZoomEnabled]);

  const setErrorCodeAndRedirect = (code: ErrorCode) => {
    AppData.update((s) => {
      s.errorCode = code;
    });
    setRedirect("/notice");
  };

  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  if (deviceParallelToGround && deviceNotMoving) {
    return <div><p>please hold the device in hand ({beta}, {gamma})</p></div>;
  }


  return (
    <MotionPage
      className="h-full"
      initial={MotionPageVariant.InitialHidden}
      animate={MotionPageVariant.FadeIn}
      exit={MotionPageVariant.FadeOut}
    >
      <OrientationLock>
        <StartComponent />
      </OrientationLock>
    </MotionPage>
  );
}

export default Start;
