import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

function TutorialImageContainer(props: Props) {
  return <div className="flex justify-center w-screen h-full overflow-hidden bg-gray-300">{props.children}</div>;
}

export default TutorialImageContainer;
